import {createApp} from 'vue'
import App from './App.vue'
import router from './router/index'

import VueChartkick from 'vue-chartkick'
import 'chartkick/chart.js'
import VueSelect from 'vue-next-select'
import 'vue-next-select/dist/index.min.css'
import "bootstrap/dist/css/bootstrap.min.css"

import 'bootstrap-icons/font/bootstrap-icons.css'
import "bootstrap"
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App)

app.component('Datepicker', Datepicker);
app.component('vue-select', VueSelect)

app.config.globalProperties.$filters = {
  translatedType(value) {
      if (!value) return ''
      if(value == 'company')
        return 'شركات'
      if(value == 'individual')
        return 'أفراد'
      if(value == 'hotel')
        return 'فندق'
      if(value == 'transfer')
        return 'انتقالات'
      if(value == 'other')
        return 'اخرى'
    }
}

router.beforeEach((to, from, next) => {
    let isAuthenticated = localStorage.getItem('isAuthenticated');
    if (to.name !== 'Login' && to.name !== 'Register' && !(isAuthenticated == 'true')) next({name: 'Login'})
    else next()
})
// router.beforeEach((to, from, next) => {
//     if (to.name == 'Login' && (isAuthenticated == 'true')) next({ name: 'Welcome' })
//     else next()
// })
app.use(router)
app.use(VueChartkick)

app.mount('#app')
