<template>
  <div class="hello">
    <div id="nav" class="sidebar_container">
      <router-link class="text-decoration-none" to="/home">
        <div class="nav_link cairo_font mb-3"><i class="bi bi-house"></i> الصفحة الرئيسية</div>
      </router-link>
      <div class="dropdown sidebar_dropdown_container mb-1">
        <button
            class="nav_link cairo_font"
            type="button"
            data-bs-auto-close="false"
            id="dropdownMenu3"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        >
          <i class="bi bi-book-half"></i> الحجوزات
        </button>

        <ul
            class="dropdown-menu reserve sidebar-dropdown-menu"
            aria-labelledby="dropdownMenu3"
        >
          <li>
            <router-link class="text-decoration-none" to="/new-reserve">
              <div class="cairo_font dropdown-item-text">إضافة حجز جديد</div>
            </router-link>
            <router-link class="text-decoration-none" to="/reservations">
              <div class="cairo_font dropdown-item-text">إجمالى الحجوزات</div>
            </router-link>
            <router-link class="text-decoration-none" to="/reservations">
              <div class="cairo_font dropdown-item-text">الحجوزات المؤكدة</div>
            </router-link>
            <router-link class="text-decoration-none" to="/extra-trips">
              <div class="cairo_font dropdown-item-text">الرحلات الإضافية</div>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="dropdown sidebar_dropdown_container mb-1">
        <button
            class="nav_link cairo_font"
            type="button"
            data-bs-auto-close="false"
            id="dropdownMenu2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        >
          <i class="bi bi-journal"></i>
          الحسابات
        </button>

        <ul
            class="dropdown-menu accounts sidebar-dropdown-menu"
            aria-labelledby="dropdownMenu2"
        >
          <li>
            <router-link class="text-decoration-none" to="/company_accounts">
              <div class="cairo_font dropdown-item-text">حسابات الشركة</div>
            </router-link>
            <router-link class="text-decoration-none" to="/income">
              <div class="cairo_font dropdown-item-text">اﻻيردات</div>
            </router-link>
            <router-link class="text-decoration-none" to="/expanse">
              <div class="cairo_font dropdown-item-text">المصروفات</div>
            </router-link>
            <router-link class="text-decoration-none" to="/safe">
              <div class="cairo_font dropdown-item-text">تقارير</div>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="dropdown sidebar_dropdown_container mb-1">
        <button
            class="nav_link cairo_font"
            type="button"
            data-bs-auto-close="false"
            id="dropdownMenu4"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        >
          <i class="bi bi-x-diamond"></i>
          الموردين
        </button>

        <ul
            class="dropdown-menu vendor sidebar-dropdown-menu"
            aria-labelledby="dropdownMenu4"
        >
          <li>
            <router-link class="text-decoration-none" to="/add-vendor">
              <div class="cairo_font dropdown-item-text">إضافة مورد</div>
            </router-link>
            <router-link class="text-decoration-none" to="/vendors">
              <div class="cairo_font dropdown-item-text">الموردين</div>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="dropdown sidebar_dropdown_container mb-1">
        <button
            class="nav_link cairo_font"
            type="button"
            data-bs-auto-close="false"
            id="dropdownMenu5"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        >
          <i class="bi bi-x-diamond-fill"></i>
          العملاء
        </button>

        <ul
            class="dropdown-menu client sidebar-dropdown-menu"
            aria-labelledby="dropdownMenu5"
        >
          <li>
            <router-link class="text-decoration-none" to="/add-client">
              <div class="cairo_font dropdown-item-text">إضافة عميل</div>
            </router-link>
            <router-link class="text-decoration-none" to="/clients">
              <div class="cairo_font dropdown-item-text">العملاء</div>
            </router-link>
          </li>
        </ul>
      </div>
      <router-link class="text-decoration-none" to="/settings">
        <div class="nav_link cairo_font font-weight-bold mb-3">اﻻعدادات</div>
      </router-link>
      <!-- <router-link class="text-decoration-none" to="/profile">
        <div class="nav_link cairo_font font-weight-bold mb-3">الحساب الشخصى</div>
      </router-link> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bi {
  margin-left: 5px;
}
</style>
