<template>
  <div id="Home">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-3">
          <div class="sticky-top">
            <side-bar/>
          </div>
        </div>
        <div class="col-12 col-lg-9">
          <div class="route_container">
            <router-view/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from './../components/SideBar'

export default {
  name: 'Home',
  data() {
    return {};
  },
  methods: {

  },
  components: {
    SideBar
  },
}
</script>
<style>

</style>
