import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
// import Login from '../views/Login.vue'
const routes = [
    {
        path: '/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "login" */ '../views/Register.vue')
    },
    {
        path: '/login',
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (login.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        children: [
            {
                path: '/:pathMatch(.*)',
                component: () => import(/* webpackChunkName: "login" */ '../views/Welcome.vue')
            },
            {
                path: '/home',
                name: 'Welcome',
                // route level code-splitting
                // this generates a separate chunk (login.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "login" */ '../views/Welcome.vue')
            },
            {
                path: '/add-account',
                name: 'Add Accounts',
                // route level code-splitting
                // this generates a separate chunk (login.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "login" */ '../views/AddAccounts.vue'),
                children: [
                    {
                        path: '/add-account/:id',
                        name: 'Add Accounts',
                        // route level code-splitting
                        // this generates a separate chunk (login.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        component: () => import(/* webpackChunkName: "login" */ '../views/AddAccounts.vue')
                    },
                ]
            },
            {
                path: '/company_accounts',
                name: 'Company_Accounts',
                // route level code-splitting
                // this generates a separate chunk (login.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "login" */ '../views/Accounts.vue')
            },
            {
                path: '/add-vendor',
                name: 'Add Vendors',
                // route level code-splitting
                // this generates a separate chunk (login.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "login" */ '../views/AddVendors.vue'),
                children: [
                    {
                        path: '/add-vendor/:id',
                        name: 'Add Vendors',
                        // route level code-splitting
                        // this generates a separate chunk (login.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        component: () => import(/* webpackChunkName: "login" */ '../views/AddVendors.vue')
                    },
                ]
            },
            {
                path: '/vendors',
                name: 'Vendors',
                // route level code-splitting
                // this generates a separate chunk (login.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "login" */ '../views/Vendors.vue')
            },
            {
                path: '/add-client',
                name: 'Add Clients',
                // route level code-splitting
                // this generates a separate chunk (login.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "login" */ '../views/AddClients.vue'),
                children: [
                    {
                        path: '/add-client/:id',
                        name: 'Add Clients',
                        // route level code-splitting
                        // this generates a separate chunk (login.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        component: () => import(/* webpackChunkName: "login" */ '../views/AddClients.vue')
                    },
                ]
            },
            {
                path: '/clients',
                name: 'Clients',
                // route level code-splitting
                // this generates a separate chunk (login.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "login" */ '../views/Clients.vue')
            },
            {
                path: '/reservations',
                name: 'Reservations',
                // route level code-splitting
                // this generates a separate chunk (login.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "login" */ '../views/Reservations.vue')
            },
            {
                path: '/safe',
                name: 'Safe',
                // route level code-splitting
                // this generates a separate chunk (login.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "login" */ '../views/Safe.vue')
            },
            {
                path: '/add-expanse',
                name: 'Add Expanses',
                // route level code-splitting
                // this generates a separate chunk (login.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "login" */ '../views/AddExpanses.vue'),
                children: [
                    {
                        path: '/add-expanse/:id',
                        name: 'Add Expanses',
                        // route level code-splitting
                        // this generates a separate chunk (login.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        component: () => import(/* webpackChunkName: "login" */ '../views/AddExpanses.vue')
                    },
                ]
            },
            {
                path: '/expanse',
                name: 'Expanse',
                // route level code-splitting
                // this generates a separate chunk (login.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "login" */ '../views/Expanse.vue')
            },
            {
                path: '/add-income',
                name: 'Add Incomes',
                // route level code-splitting
                // this generates a separate chunk (login.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "login" */ '../views/AddIncomes.vue'),
                children: [
                    {
                        path: '/add-income/:id',
                        name: 'Add Incomes',
                        // route level code-splitting
                        // this generates a separate chunk (login.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        component: () => import(/* webpackChunkName: "login" */ '../views/AddIncomes.vue')
                    },
                ]
            },
            {
                path: '/income',
                name: 'Income',
                // route level code-splitting
                // this generates a separate chunk (login.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "login" */ '../views/Income.vue')
            },
            {
                path: '/ensure-reserve',
                name: 'Ensure-Reserve',
                // route level code-splitting
                // this generates a separate chunk (login.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "login" */ '../views/EnsureReserve.vue')
            },
            {
                path: '/extra-trips',
                name: 'Extra-Trips',
                // route level code-splitting
                // this generates a separate chunk (login.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "login" */ '../views/ExtraTrips')
            },
            {
                path: '/new-reserve',
                name: 'New-Reserve',
                // route level code-splitting
                // this generates a separate chunk (login.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "login" */ '../views/NewReserve'),
                children: [
                    {
                        path: '/new-reserve/:id',
                        name: 'New-Reserve',
                        // route level code-splitting
                        // this generates a separate chunk (login.[hash].js) for this route
                        // which is lazy-loaded when the route is visited.
                        component: () => import(/* webpackChunkName: "login" */ '../views/NewReserve')
                    },
                ]
            },
            {
                path: '/settings',
                name: 'Settings',
                // route level code-splitting
                // this generates a separate chunk (login.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "login" */ '../views/Settings')
            },
            {
                path: '/profile',
                name: 'Profile',
                // route level code-splitting
                // this generates a separate chunk (login.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "login" */ '../views/Profile')
            },
        ]
    },
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
export default router